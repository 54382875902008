<script setup lang="ts">
import AppLinkedAccount from '~/components/app/AppLinkedAccount.vue';
</script>
<template>
  <div class="flex flex-col items-start gap-6">
    <UFormGroup
      label="CRM"
      description="The SDR agent will reach out to leads from your connected CRM and will add new tagged leads to it ."
      class="w-full"
    >
      <AppLinkedAccount service="hubspot" />
    </UFormGroup>
    <UFormGroup
      label="Email sender"
      description="Connect the email account you want SDR to use to send emails to prospects"
      class="w-full"
    >
      <AppLinkedAccount service="gmail" />
    </UFormGroup>
  </div>
</template>
